import type { BadgeSizes } from '@/components/common/Badge';
import type { HeadingSanityBlockType } from '@/components/common/Heading';
import type { SizeOptions, Variants } from '@/components/common/Title/types';
import type { StackProps } from '@/components/layout/Stack';
import type { TypographySizeTypes } from '@/styles/typography';

const stackSpacing = (
  size: SizeOptions,
  variant: Variants,
): StackProps['spacing'] => {
  const spacing: Record<
    Variants,
    Record<SizeOptions, StackProps['spacing']>
  > = {
    hero: {
      xl: '--space-component-xxl',
      lg: '--space-component-xxl',
      md: '--space-component-xl',
      sm: '--space-component-xl',
    },
    component: {
      xl: '--space-component-lg',
      lg: '--space-component-lg',
      md: '--space-component-md',
      sm: '--space-component-md',
    },
  };

  return spacing[variant][size];
};

const headingSize = (
  size: SizeOptions,
  variant: Variants,
  heading: HeadingSanityBlockType,
) => {
  const sizeMap: Record<SizeOptions, Record<Variants, string>> = {
    xl: {
      hero: 'display-xl',
      component: 'heading-lg',
    },
    lg: {
      hero: 'display-lg',
      component: 'heading-lg',
    },
    md: {
      hero: 'display-md',
      component: 'heading-md',
    },
    sm: {
      hero: 'display-sm',
      component: 'heading-sm',
    },
  };

  heading[0].style = sizeMap[size][variant];
  return heading[0].style;
};

const subHeadingSize = (
  size: SizeOptions,
  variant: Variants,
  smallSubheading: boolean,
): TypographySizeTypes => {
  const sizeMap: Record<SizeOptions, Record<Variants, TypographySizeTypes>> = {
    xl: {
      hero: smallSubheading ? 'subheading-md' : 'subheading-lg',
      component: smallSubheading ? 'body-lg' : 'subheading-sm',
    },
    lg: {
      hero: smallSubheading ? 'subheading-md' : 'subheading-lg',
      component: smallSubheading ? 'body-lg' : 'subheading-sm',
    },
    md: {
      hero: smallSubheading ? 'subheading-sm' : 'subheading-md',
      component: smallSubheading ? 'body-md' : 'body-lg',
    },
    sm: {
      hero: smallSubheading ? 'subheading-sm' : 'subheading-md',
      component: smallSubheading ? 'body-md' : 'body-lg',
    },
  };

  return sizeMap[size][variant];
};

const captionSize = (
  size: SizeOptions,
  variant: Variants,
): TypographySizeTypes => {
  const sizeMap: Record<SizeOptions, Record<Variants, TypographySizeTypes>> = {
    xl: {
      hero: 'label-lg',
      component: 'label-md',
    },
    lg: {
      hero: 'label-lg',
      component: 'label-md',
    },
    md: {
      hero: 'label-md',
      component: 'label-sm',
    },
    sm: {
      hero: 'label-md',
      component: 'label-sm',
    },
  };

  return sizeMap[size][variant];
};

const badgeSize = (size: SizeOptions, variant: Variants): BadgeSizes => {
  const sizeMap: Record<SizeOptions, Record<Variants, BadgeSizes>> = {
    xl: {
      hero: 'lg',
      component: 'md',
    },
    lg: {
      hero: 'lg',
      component: 'md',
    },
    md: {
      hero: 'md',
      component: 'sm',
    },
    sm: {
      hero: 'md',
      component: 'sm',
    },
  };

  return sizeMap[size][variant];
};

export { stackSpacing, headingSize, subHeadingSize, captionSize, badgeSize };
