import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useIntersectionObserver } from '@react-hookz/web';
import {
  Autoplay,
  EffectFade,
  FreeMode,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper/modules';
import { Swiper as SwiperJS, SwiperSlide } from 'swiper/react';

import { ColourfulArrows } from '@/components/common/Swiper/Custom/Navigation/ColourfulArrows';
import { DefaultArrows } from '@/components/common/Swiper/Custom/Navigation/DefaultArrows';
import {
  DetailedPagination,
  DetailedPaginationContainer,
  ProgressBar,
  ProgressBarInner,
} from '@/components/common/Swiper/Custom/Pagination/DetailedPagination';
import {
  MobileDetailedPagination,
  MobileDetailedPaginationContainer,
  MobileDetailedPaginationTab,
  MobileIndicatorContainer,
} from '@/components/common/Swiper/Custom/Pagination/MobileDetailedPagination';
import {
  PipContainer,
  PipPagination,
} from '@/components/common/Swiper/Custom/Pagination/PipPagination';
import {
  SimplePagination,
  SimplePaginationContainer,
} from '@/components/common/Swiper/Custom/Pagination/SimplePagination';
import { useScreenSize } from '@/lib/useScreenSize';
import { useSpacingValue } from '@/lib/useSpacingValue';

import type {
  blockSpacingOptions,
  componentSpacingOptions,
} from '@/types/measures';
import type { ReactElement } from 'react';
import type { NavigationOptions, SwiperOptions } from 'swiper/types';

import 'swiper/css';
import 'swiper/css/free-mode';

import { screen } from '@/components/common/breakpoints';
import { getBreakpointPixelValue } from '@/lib/utils';

import type { BreakpointKeys } from '@/components/common/breakpoints';

interface SlidesPerConfig {
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  max?: number;
}
export interface SwiperProps {
  className?: string;
  autoplay?: {
    // enabled: true to turn on autoplay (default transition speed is 2500ms)
    enabled?: boolean;

    // speed: controls the transition speed in ms
    speed?: number;

    showProgress?: boolean;
  };

  // centeredSlides: enable to always center the slide which is the focus, other slides will be cutoff depending on the container size
  centeredSlides?: boolean;

  // effect: alters the transition style between slides
  effect?: 'slide' | 'fade';

  // freeMode: enable to allow smooth, non-snapping movement between slides which encorporates velocity and momentum of a swipe
  freeMode?: boolean;

  navigation?: {
    // enabled: true to turn on controls as a feature
    enabled?: boolean;

    // type: controls which navigation component to use
    type?: 'default' | 'colourful';
  };

  pagination?: {
    // enabled: true to turn on pagination as a feature
    enabled?: boolean;

    // type: controls which pagination component to use
    type?: 'detailed' | 'pip' | 'progressbar' | 'simple';

    // a custom component that can be inserted into the pagination button - this could be a company logo to depict that comapny's testimonial for example
    components?: ReactElement[];
  };

  // slides: controls the content of the carousel
  slides: ReactElement[];

  // slidesPerView: how many slides are visible on the screen at one time at all the breakpoints
  slidesPerView?: SlidesPerConfig;

  // slidesPerGroup: Set numbers of slides to define and enable group sliding. Useful to use with slidesPerView > 1
  slidesPerGroup?: SlidesPerConfig;

  // spaceBetween: this controls the gap between the slides: it becomes the margin-right of a slide
  spaceBetween?: componentSpacingOptions | blockSpacingOptions | 0;

  // Allows to set different parameter for different responsive breakpoints (screen sizes).
  breakpoints?: {
    base?: SwiperOptions;
    sm?: SwiperOptions;
    md?: SwiperOptions;
    lg?: SwiperOptions;
    xl?: SwiperOptions;
  };
}

export const Swiper = ({
  autoplay,
  centeredSlides,
  effect,
  freeMode = false,
  navigation,
  pagination,
  slides,
  slidesPerView,
  slidesPerGroup,
  spaceBetween = 0,
  className,
  breakpoints,
}: SwiperProps) => {
  const swiperRef = useRef(null);
  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);
  const progressBarRef = useRef(null);
  const mobileProgressBarRef = useRef(null);
  const currentBreakpoint = useScreenSize();
  const [swiperInstance, setSwiperInstance] = useState(null);

  const swiperViewport = useIntersectionObserver(swiperRef.current, {
    threshold: [0],
    rootMargin: '300px',
  });

  useEffect(() => {
    // .pause()/.resume() seems to be buggy
    if (autoplay?.enabled) {
      if (!swiperViewport?.isIntersecting) {
        swiperInstance?.autoplay.stop();
      } else {
        swiperInstance?.autoplay.start();
      }
    }
  }, [autoplay?.enabled, swiperInstance, swiperViewport?.isIntersecting]);

  const autoplaySettings = {
    delay: autoplay?.speed ?? 5000,
    disableOnInteraction: true,
    pauseOnMouseEnter: true,
    stopOnLastSlide: true,
  };

  const slidesPerSettings = (config: SlidesPerConfig) => ({
    sm: config?.sm || 1,
    md: config?.md || 1,
    lg: config?.lg || 1,
    xl: config?.xl || 1,
    max: config?.max || 1,
  });

  const paginationSettings = {
    clickable: true,
  };

  const progressBarSettings = {
    enabled: true,
    draggable: true,
    dragClass: 'progress-bar-inner',
    dragSize: 32,
    el: '.progressbar > .styled-progress-bar',
  };

  const navigationSettings: NavigationOptions = {
    nextEl: nextButtonRef.current,
    prevEl: prevButtonRef.current,
  };

  const onAutoplayTimeLeft = (_s, _time, progress) => {
    if (progress >= 0) {
      progressBarRef.current?.style.setProperty('--progress', 1 - progress);
      mobileProgressBarRef.current?.style.setProperty(
        '--progress',
        1 - progress,
      );
    }
  };

  const swiperBreakpointsResolved = () => {
    const breakpointsResolved = {};
    for (const [key, value] of Object.entries(breakpoints)) {
      breakpointsResolved[getBreakpointPixelValue(key as BreakpointKeys)] =
        value;
    }
    return breakpointsResolved;
  };

  const renderPagination = (type: SwiperProps['pagination']['type']) => {
    const totalPips = Math.ceil(
      slides.length / slidesPerSettings(slidesPerView)[currentBreakpoint],
    ); // e.g. 7 slides / 2 slides per view = 4 pips

    switch (type) {
      case 'detailed':
        return (
          <>
            <MobileDetailedPaginationContainer>
              <MobileIndicatorContainer>
                {slides.map((slide, index) => (
                  <MobileDetailedPagination
                    key={slide.key}
                    index={index}
                    slidesPerView={
                      slidesPerSettings(slidesPerView)[currentBreakpoint]
                    }
                    progressBarRef={mobileProgressBarRef}
                  />
                ))}
              </MobileIndicatorContainer>
              <MobileDetailedPaginationTab
                components={pagination?.components}
              />
            </MobileDetailedPaginationContainer>

            <DetailedPaginationContainer
              className="custom-pagination"
              numberOfLogos={slides.length}
            >
              {slides.map((_, index) => (
                <DetailedPagination
                  key={slides[index].key}
                  index={index}
                  slidesPerView={
                    slidesPerSettings(slidesPerView)[currentBreakpoint]
                  }
                  component={pagination?.components[index]}
                  progressBarRef={progressBarRef}
                />
              ))}
            </DetailedPaginationContainer>
          </>
        );
      case 'simple':
        return (
          <SimplePaginationContainer>
            {slides.map((slide, index) => (
              <SimplePagination
                key={slide.key}
                index={index}
                slidesPerView={
                  slidesPerSettings(slidesPerView)[currentBreakpoint]
                }
                progressBarRef={mobileProgressBarRef}
              />
            ))}
          </SimplePaginationContainer>
        );
      case 'progressbar':
        return (
          <StyledProgressBar className="styled-progress-bar" isActive={true}>
            <ProgressBarInner className="progress-bar-inner" />
          </StyledProgressBar>
        );
      default:
      case 'pip':
        return (
          <PipContainer className="custom-pagination">
            {Array.from({ length: totalPips }).map((_, index) => (
              <PipPagination
                key={index}
                slidesPerView={
                  slidesPerSettings(slidesPerView)[currentBreakpoint]
                }
                pipIndex={index}
              />
            ))}
          </PipContainer>
        );
    }
  };

  const renderNavigation = (type) => {
    switch (type) {
      case 'colourful':
        return (
          <ColourfulArrows
            prevButtonRef={prevButtonRef}
            nextButtonRef={nextButtonRef}
          />
        );
      case 'default':
        return (
          <DefaultArrows
            prevButtonRef={prevButtonRef}
            nextButtonRef={nextButtonRef}
          />
        );
    }
  };

  return (
    <>
      <Detector ref={swiperRef} />
      <SwiperJS
        autoplay={autoplay?.enabled && autoplaySettings}
        centeredSlides={centeredSlides}
        effect={effect}
        freeMode={freeMode}
        modules={[
          Autoplay,
          Pagination,
          Navigation,
          FreeMode,
          EffectFade,
          Scrollbar,
        ]}
        navigation={navigation?.enabled && navigationSettings}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSwiper={(swiper) => setSwiperInstance(swiper)}
        pagination={pagination?.enabled && paginationSettings}
        slidesPerView={slidesPerSettings(slidesPerView)[currentBreakpoint]}
        slidesPerGroup={slidesPerSettings(slidesPerGroup)[currentBreakpoint]}
        spaceBetween={useSpacingValue(spaceBetween)}
        watchSlidesProgress={true}
        scrollbar={pagination?.type === 'progressbar' && progressBarSettings}
        breakpoints={!!breakpoints && swiperBreakpointsResolved()}
      >
        {slides.map((slide) => {
          return (
            <SwiperSlide className={className} key={slide.key}>
              {slide}
            </SwiperSlide>
          );
        })}
        <ControlsContainer className={pagination?.type}>
          {pagination?.enabled && renderPagination(pagination?.type || 'pip')}
          {navigation?.enabled &&
            renderNavigation(navigation?.type || 'default')}
        </ControlsContainer>
      </SwiperJS>
    </>
  );
};

const ControlsContainer = styled.div`
  display: flex;

  justify-content: space-between;

  &.detailed {
    margin-top: var(--space-block-md);

    ${screen.md} {
      margin-top: var(--space-block-lg);
    }
  }

  &.progressbar {
    padding-top: var(--space-block-md);
  }

  &.simple,
  &.pip {
    padding: var(--space-block-md) var(--space-component-sm) 0
      var(--space-component-xl);
  }

  &.detailed,
  &.simple,
  &.progressbar {
    justify-content: center;
  }
`;

const Detector = styled.div`
  position: relative;
  height: 1px;
`;

// Styled for SliderCarousel
const StyledProgressBar = styled(ProgressBar)`
  position: relative;
  width: 112px;
  ${screen.sm} {
    width: 144px;
  }
  justify-content: center;
  margin-top: var(--space-component-xl);

  ${ProgressBarInner} {
    width: 32px;
  }
`;
