import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { Badge } from '@/components/common/Badge';
import { screen } from '@/components/common/breakpoints';
import { resolveSize, RichHeading } from '@/components/common/Heading';
import { Paragraph } from '@/components/common/MarkUp';
import {
  badgeSize,
  captionSize,
  headingSize,
  stackSpacing,
  subHeadingSize,
} from '@/components/common/Title/helpers';
import { Stack } from '@/components/layout/Stack';

import type {
  AlignmentOptions,
  TitleProps,
  Variants,
} from '@/components/common/Title/types';

export const Title = ({
  badge,
  badgeColour,
  caption,
  heading,
  subheading,
  smallSubheading = false,
  desktopAlignment = 'start',
  mobileAlignment = 'start',
  hidden = false,
  variant = 'hero',
  size = 'xl',
  isNarrow = false,
}: TitleProps) => {
  if (hidden) return null;

  return (
    <TitleContainer
      desktopAlignment={desktopAlignment}
      mobileAlignment={mobileAlignment}
      variant={variant}
      className="title"
    >
      <TitleStack
        desktopAlignment={desktopAlignment}
        mobileAlignment={mobileAlignment}
        spacing={stackSpacing(size, variant)}
        isNarrow={isNarrow}
      >
        {badge && (
          <Badge
            size={badgeSize(size, variant)}
            bgColour={badgeColour}
            margin="0"
          >
            {badge}
          </Badge>
        )}
        {caption && (
          <Caption size={captionSize(size, variant)}>{caption}</Caption>
        )}
        {heading && (
          <RichHeading
            heading={heading}
            size={resolveSize(heading, headingSize(size, variant, heading))}
          />
        )}
        {subheading && (
          <Subheading size={subHeadingSize(size, variant, smallSubheading)}>
            {subheading}
          </Subheading>
        )}
      </TitleStack>
    </TitleContainer>
  );
};

const TitleContainer = styled.div<{
  mobileAlignment: AlignmentOptions;
  desktopAlignment: AlignmentOptions;
  variant: Variants;
}>`
  display: flex;
  flex-direction: column;

  ${({ mobileAlignment }) => css`
    align-items: ${mobileAlignment};
    text-align: ${mobileAlignment};
    justify-items: ${mobileAlignment};
  `}

  ${screen.sm} {
    ${({ desktopAlignment }) => css`
      align-items: ${desktopAlignment};
      text-align: ${desktopAlignment};
      justify-items: ${desktopAlignment};
    `}
  }
`;

const TitleStack = styled(Stack)<{
  mobileAlignment: AlignmentOptions;
  desktopAlignment: AlignmentOptions;
  isNarrow: boolean;
}>`
  display: flex;

  ${({ isNarrow }) =>
    isNarrow &&
    css`
      padding: 0 var(--space-block-md);
      ${screen.sm} {
        padding: 0;
      }
    `}

  ${({ mobileAlignment }) => css`
    align-items: ${mobileAlignment};
  `}

  ${screen.md} {
    ${({ desktopAlignment }) => css`
      align-items: ${desktopAlignment};
    `}
  }
`;

const Caption = styled(Paragraph)`
  color: var(--text-warm-subtle);
`;

const Subheading = styled(Paragraph)`
  color: var(--text-warm-base);
`;
