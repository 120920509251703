import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  findClosestBreakpoint,
  mapFromBreakpointObject,
  screen,
} from '@/components/common/breakpoints';
import { HeadingLevelProvider } from '@/components/common/Heading/context';
import {
  ResponsiveImage,
  sortEntriesByBreakpoint,
} from '@/components/common/ResponsiveImage/ResponsiveImage';
import { Surface } from '@/components/common/Surface';
import { Title } from '@/components/common/Title';
import { Stack } from '@/components/layout/Stack';

import type { BreakpointKeys } from '@/components/common/breakpoints';
import type {
  AlignmentOptions,
  TitleProps,
} from '@/components/common/Title/types';
import type { SurfaceColourTokenType } from '@/types/colours';

type Alignment = 'left' | 'center' | 'right';
type ContainerByBreakpoint = Map<BreakpointKeys, string>;
type RatiosByBreakpoints = [BreakpointKeys, number][];
type ImageAlignByBreakpoint = [BreakpointKeys, Alignment][];

interface FeatureCardProps {
  surfaceColour: SurfaceColourTokenType;

  title?: Pick<
    TitleProps,
    'badge' | 'badgeColour' | 'caption' | 'heading' | 'subheading'
  >;
  textAlign?: AlignmentOptions;
  textPosition?: 'top' | 'bottom';

  containerSize: { [K in BreakpointKeys]?: string };
  responsiveImage?: ResponsiveImage<{
    align?: Alignment;
  }>;
}

export const FeatureCard = ({
  title,
  textAlign,
  textPosition = 'top',
  responsiveImage,
  surfaceColour,
  containerSize = { base: '420px', md: '520px' },
}: FeatureCardProps) => {
  const { badge, badgeColour, caption, heading, subheading } = title ?? {};

  const titleExists = Boolean(heading || subheading);

  const titleElem = titleExists ? (
    <Title
      badge={badge}
      badgeColour={badgeColour}
      caption={caption}
      heading={heading}
      subheading={subheading}
      desktopAlignment={textAlign}
      mobileAlignment={textAlign}
      variant="component"
      size="lg"
    />
  ) : null;

  const images = () => {
    if (responsiveImage?.images?.length) {
      const sortedImages = sortEntriesByBreakpoint(responsiveImage.images);

      return (
        <ImageContainer
          textPosition={titleExists ? textPosition : 'none'}
          aspectRatio={sortedImages.map((item) => [
            item.breakpoint,
            item.image.asset.metadata.dimensions.aspectRatio,
          ])}
          imageAlign={sortedImages.map((item) => [item.breakpoint, item.align])}
          containerSize={mapFromBreakpointObject(containerSize)}
        >
          <ResponsiveImage responsiveImage={responsiveImage} />
        </ImageContainer>
      );
    }

    return null;
  };

  return (
    <Surface
      borderRadius="--radius-component-xl"
      surfaceColour={surfaceColour}
      padding="--space-component-xxl"
      outline
      fill
      data-tp={textPosition}
    >
      <HeadingLevelProvider level={3}>
        <StyledStack spacing="--space-component-xxl" titleExists={titleExists}>
          {textPosition === 'top'
            ? [titleElem, images()]
            : [images(), titleElem]}
        </StyledStack>
      </HeadingLevelProvider>
    </Surface>
  );
};

const StyledStack = styled(Stack)<{ titleExists: boolean }>`
  height: 100%;
  justify-content: ${({ titleExists }) => (!titleExists ? 'end' : 'normal')};
`;

const getRatios = (
  ratioEntries: RatiosByBreakpoints,
  containerSize: ContainerByBreakpoint,
) => {
  // TODO: Fix so that the breakpoints are inserted for all sizes, rather than only when a ratio exists.
  if (ratioEntries) {
    return ratioEntries.reduce(
      (prev, [bp, ratio]) => css`
        ${prev}
        ${screen[bp]} {
          height: calc(${findClosestBreakpoint(containerSize, bp)} / ${ratio});
        }
      `,
      css``,
    );
  }
  return null;
};

const alignSwitch = (align: Alignment) => {
  switch (align) {
    case 'left':
      return css`
        img {
          left: 0;
          right: unset;
          transform: unset;
        }
      `;

    case 'right':
      return css`
        img {
          right: 0;
          left: unset;
          transform: unset;
        }
      `;

    case 'center':
    default:
      return css`
        img {
          left: 50%;
          right: unset;
          transform: translateX(-50%);
        }
      `;
  }
};

const getAlignment = (alignEntries: ImageAlignByBreakpoint) => {
  if (alignEntries) {
    return alignEntries.reduce(
      (prev, [bp, align]) => css`
        ${prev}
        ${screen[bp]} {
          ${alignSwitch(align)}
        }
      `,
      css``,
    );
  }

  return null;
};

type AlignmentProps = {
  textPosition: 'top' | 'bottom' | 'none';
  imageAlign: ImageAlignByBreakpoint;
  aspectRatio: RatiosByBreakpoints;
  containerSize: ContainerByBreakpoint;
};

const ImageContainer = styled.div<AlignmentProps>`
  position: relative;
  margin-inline: calc(var(--space-component-xxl) * -1);

  ${(props) => getRatios(props.aspectRatio, props.containerSize)}

  ${(props) => getAlignment(props.imageAlign)}

  img {
    position: absolute;

    height: 100%;
    width: auto;

    object-fit: cover;
    object-position: center;
  }

  ${(props) => {
    // Control image vertical alignment
    switch (props.textPosition) {
      case 'top':
        return css`
          margin-bottom: calc(var(--space-component-xxl) * -1);
          margin-top: auto;

          img {
            bottom: 0;
          }
        `;

      case 'bottom':
        return css`
          margin-top: calc(var(--space-component-xxl) * -1);
          margin-bottom: auto;

          img {
            top: 0;
          }
        `;

      case 'none':
        return css`
          margin-block: calc(var(--space-component-xxl) * -1);

          img {
            bottom: 0;
          }
        `;

      default:
        return css`
          img {
            bottom: 0;
          }
        `;
    }
  }}
`;
