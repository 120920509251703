import styled from '@emotion/styled';

import { Swiper } from '@/components/common/Swiper';
import { Section } from '@/components/layout/Section';

import 'swiper/css/effect-fade';

import { SliderCarouselCard } from '@/components/blocks/SliderCarousel/Card/SliderCarouselCard';
import { screen } from '@/components/common/breakpoints';
import { useMediaQuery } from '@/lib/useMediaQuery';

import type { SliderCarouselCardProps } from '@/components/blocks/SliderCarousel/Card/SliderCarouselCard';
import type {
  SanityAudienceType,
  SanitySliderCarouselType,
} from '@/types/sanity';
import type { BlockWithAudience } from '@/types/shared';

export interface SliderCarouselProps
  extends Omit<SanitySliderCarouselType, 'slides'>,
    BlockWithAudience {
  slides?: Array<
    Omit<SliderCarouselCardProps, 'audienceRef'> & {
      audienceRef?: SanityAudienceType;
    }
  >;
}

export const SliderCarousel = ({
  slides,
  pageAudience,
  isAudienceSwitcherEnabled,
  blockSpacing,
  paddingAdjustment,
}: SliderCarouselProps) => {
  const maxAmountCTA = () => {
    if (slides.some((slide) => slide.secondaryCTA?.text)) {
      return 2;
    } else if (slides.some((slide) => slide.primaryCTA?.text)) {
      return 1;
    }
    return 0;
  };

  const slideComponents = slides.map((slide) => {
    if (slide._type === 'sliderCarouselCard') {
      return (
        <SliderCarouselCard
          key={slide._key}
          maxAmountCTA={maxAmountCTA()}
          pageAudience={pageAudience}
          isAudienceSwitcherEnabled={isAudienceSwitcherEnabled}
          {...slide}
        />
      );
    }
  });

  const isDesktop = useMediaQuery(screen.mdQuery);

  return (
    <SliderCarouselContainer
      className="slider-carousel-container"
      verticalPadding={paddingAdjustment ?? true}
      spacing={blockSpacing}
    >
      <Swiper
        slides={slideComponents}
        pagination={{
          enabled: true,
          type: isDesktop ? 'pip' : 'progressbar',
        }}
        navigation={{
          enabled: isDesktop,
        }}
        effect="slide"
        autoplay={{ enabled: false }}
        slidesPerView={{
          sm: 1.05,
          md: 2,
          lg: 3.01,
          xl: 3.01,
          max: 3.01,
        }}
        slidesPerGroup={{
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          max: 3,
        }}
        breakpoints={{
          base: {
            spaceBetween: 20,
            freeMode: {
              enabled: true,
            },
          },
          md: {
            spaceBetween: 20,
            freeMode: {
              enabled: false,
            },
          },
          lg: {
            spaceBetween: 24,
          },
        }}
      />
    </SliderCarouselContainer>
  );
};

const SliderCarouselContainer = styled(Section)`
  .swiper {
    overflow: visible;
  }

  .swiper-slide {
    height: auto;
  }

  // On desktop adding opacity animation on previous and next slides
  ${screen.md} {
    .swiper-slide {
      opacity: 0.24;
      transition-property: opacity;
      transition-duration: 0.25s;
      transition-timing-function: ease-in;
    }

    .swiper-slide-fully-visible {
      opacity: 1;
    }
    .swiper-slide-active {
      opacity: 1;
    }

    // Due to some flickering issues on specific width of the screen,
    // this rule checks if there're 3 fully-visible elements or 2 fully-visible + active,
    // if there're not, add an opacity:1 to the last element

    .swiper-wrapper:not(
        :has(.swiper-slide-fully-visible:nth-last-of-type(4))
      ):not(:has(.swiper-slide-active:nth-last-of-type(4))) {
      .swiper-slide:nth-last-of-type(1) {
        opacity: 1;
      }
    }
  }
`;
