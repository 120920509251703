import { useEffect, useState } from 'react';

import { breakpoints } from '@/components/common/breakpoints';
import { getCurrentBreakpoint } from '@/lib/utils';

export const useScreenSize = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState('max');

  useEffect(() => {
    const updateBreakpointOnResize = () => {
      const windowWidth = window.innerWidth;
      setCurrentBreakpoint(getCurrentBreakpoint(breakpoints, windowWidth));
    };
    window.addEventListener('resize', updateBreakpointOnResize);

    if (
      getCurrentBreakpoint(breakpoints, window.innerWidth) !== currentBreakpoint
    ) {
      setCurrentBreakpoint(
        getCurrentBreakpoint(breakpoints, window.innerWidth),
      );
    }

    return () => {
      window.removeEventListener('resize', updateBreakpointOnResize);
    };
  }, [currentBreakpoint]);

  return currentBreakpoint;
};
